import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Users associated on “Users Tab”`}</h1>
    <p>{`In this tab, you have the option to not only create new users, but also manage the users of one team/skill. In the image below, a list of users is presented with some extra information:`}</p>
    <ul>
      <li parentName="ul">{`The color around user icon present the user status;`}
        <ul parentName="li">
          <li parentName="ul">{`Black: inactive;`}</li>
          <li parentName="ul">{`Grey: logged in, not available;`}</li>
          <li parentName="ul">{`Green: logged in, available;`}</li>
          <li parentName="ul">{`Red: logged in, paused;`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Username;`}</li>
      <li parentName="ul">{`Skill types associated;`}</li>
      <li parentName="ul">{`Profile (role);`}</li>
      <li parentName="ul">{`Code (DTMF);`}</li>
      <li parentName="ul">{`Last access date.`}</li>
    </ul>
    <p>{`Here, you have the options to:`}</p>
    <ul>
      <li parentName="ul">{`Edit user info;`}</li>
      <li parentName="ul">{`Deactivate / Activate.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`User-level settings override the generic settings for clients, teams, skilltypes and skills.`}</p>
    </blockquote>
    <p>{`Supervisors or managers can configure some parameters regarding operators:`}</p>
    <h3>{`General`}</h3>
    <h4>{`Data`}</h4>
    <ul>
      <li parentName="ul">{`Personal data about each user/operator can be configured, namely:`}
        <ul parentName="li">
          <li parentName="ul">{`Full name;`}</li>
          <li parentName="ul">{`User Identifier (username);`}</li>
          <li parentName="ul">{`Email;`}</li>
          <li parentName="ul">{`Mobile phone;`}</li>
          <li parentName="ul">{`Groups (Role);`}</li>
          <li parentName="ul">{`Teams (if operator or supervisor only one team is possible);`}</li>
          <li parentName="ul">{`Tags;`}</li>
          <li parentName="ul">{`Additional information;`}</li>
          <li parentName="ul">{`Password.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Backoffice`}</h4>
    <p>{`Editable parameters regarding CoreMedia web session can be found, such as:`}</p>
    <pre><code parentName="pre" {...{}}>{`* Session expiration (generic definition, never expire, or expiration in a defined period of minutes);
* Language;
* Force the change of password on the next login.
`}</code></pre>
    <h4>{`Achievements`}</h4>
    <p>{`List of badges won by this user/operator. This badges are related with gamification functionality.`}</p>
    <h3>{`Skills`}</h3>
    <p>{`In this tab, is possible to activate and deactivate skills, associated with this team, particularly for this user/operator. These Skills are grouped by skill types.`}</p>
    <h3>{`Service`}</h3>
    <p>{`In this tab, you have the option to set up service parameters related to the user/operator and the CoreMedia platform.`}</p>
    <h4>{`Identifiers`}</h4>
    <p>{`Set up user identifiers, like "Operator code" (used for DTMF operator identification) or "External code" (for external services correlation).`}</p>
    <h4>{`Assisted support`}</h4>
    <p>{`When enabled, allows the operator to make a remote call (CoBrowsing) to the visitor to help him with a topic, problem, doubt, and/or to present a product.`}</p>
    <h4>{`Alerts`}</h4>
    <p>{`Possibility to configure alerts for this specific user/operator. You can use the information from the teams or rewrite with the same options as before.`}</p>
    <h4>{`General`}</h4>
    <ul>
      <li parentName="ul">{`Operator can make outbound contact;`}</li>
      <li parentName="ul">{`Operator can hangup calls;`}</li>
      <li parentName="ul">{`Operator can transfer files; `}</li>
    </ul>
    <h3>{`Activity`}</h3>
    <p>{`In this tab, you can access the operator’s activity log and view breaks, logins and logouts. Filters can be set by channel, state and mode (inbound vs. outbound), in defined time periods are available.`}</p>
    <h3>{`Contacts`}</h3>
    <p>{`In this tab, you can see all contacts associated with this operator, for a specific time period. Filters can be set by skill, classification and contact with sales or not are available.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      